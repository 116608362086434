import React from 'react';
// import sections
import NFTIntroComponent from '../components/sections/NFTIntroComponent';

const NFTIntro = () => {

  return (
    <>
      <NFTIntroComponent />

    </>
  );
}

export default NFTIntro;