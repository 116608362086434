import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  // const sectionHeader = {
  //   title: 'ABOUT LIBIT CAT',
  //   paragraph: ''
  // };

  return (
    <section {...props} className={outerClasses}>
      <div id="contact" className="container contact-sm">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left split-item-contact"
                data-reveal-container=".split-item"
              >
                <Image
                  src={
                    require("./../../assets/images/web_application.svg").default
                  }
                  alt="Features split 03"
                  className={"testimonial-item-image"}
                />
                <div className="text-xxs text-color-dark fw-600 tt-u mb-8">
                  We'll help perfect your business.
                </div>
                <p className="text-sm text-color-dark mt-32 mb-0 contact--content-small">
                  Partner with us to leverage our expertise, commitment to
                  excellence, and dedication to making the world a more exciting
                  place.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/bussiness_success.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div> */}

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left split-item-contact"
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/contact-us.png")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
              <div
                className={classNames(
                  "center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-dark fw-600 tt-u mb-8">
                  <h1>CONTACT US</h1>
                </div>
                
                <h3 style={{color: 'rgb(164, 194, 219)', marginBottom: 0}}>PHONE</h3>
                <h6 style={{marginTop: 8}}><a href="tel:+84818 31 4546">0818 31 4546</a></h6>

                
                <h3 style={{color: 'rgb(164, 194, 219)', marginBottom: 0}}>EMAIL</h3>
                <h6 style={{marginTop: 8}}><a href="mailto:tanksolutionsvietnam@gmail.com">tanksolutionsvietnam@gmail.com</a></h6>
              </div>
            </div>

            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  IDEA
                </div>
                <h3 className="mt-0 mb-12">Auto Liquidity</h3>
                <p className="m-0">
                  LIBIT CAT combines this with an innovative Auto-Liquidity
                  feature that increases the liquidity of the token rapidly. And
                  finally, LIBIT CAT has massive decentralization on a scale
                  rarely seen in other tokens. Combine these three together and
                  you get a powerhouse token out of the hands of anyone, except
                  the community as a whole.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/cat-moon-gif.gif")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div> */}

            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <h3 className="mt-0 mb-12">Why Choose LIBIT CAT?</h3>

                <p className="m-0">
                  No wallet holds more than 1% of the token supply and no
                  transaction can involve more than 5 trillion tokens.
                </p>
                <p className="m-0">
                  Burn token to the dead wallet for every transaction forever.
                </p>
                <p className="m-0">
                  Exponential burn, exponential price growth!
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/cat-moon-03.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div> */}

            {/* <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                
                <h3 className="mt-0 mb-12">Transaction</h3>
                <p className="m-0">60% sent to the dead wallet at launch.</p>
                <p className="m-0">Transaction burn: 3%.</p>
                <p className="m-0">Transaction sends to the holder: 1%.</p>
                <p className="m-0">Transaction fee: 1% sent to CZ.</p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/cat-moon-gif2.gif")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
