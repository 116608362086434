import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Our Services",
    paragraph:
      "Our approach combines a tech-savvy culture, a product-oriented methodology, and a strong focus on achieving business goals to create high-quality software products.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div id="SOCIALS" className="container holder-sm">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={
                      require("./../../assets/images/mobile_application.svg")
                        .default
                    }
                    alt="Features split 03"
                    className={'testimonial-item-image'}
                  />
                  <p
                    className="text-sm mb-0"
                    style={{ color: "rgb(54, 86, 121)" , fontWeight: 700}}
                  >
                    MOBILE APPLICATION
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <div className="testimonial-item-name text-color-low" style={{fontSize: '20px', lineHeight: '32px'}}>
                   We craft user-friendly mobile apps that deliver impact and value, tailored to your specific needs across industries.
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/web_application.svg").default}
                    alt="Features split 03"
                    className={'testimonial-item-image'}
                  />
                  <p
                    className="text-sm mb-0"
                    style={{ color: "rgb(54, 86, 121)" , fontWeight: 700}}
                  >
                    WEB APPLICATION
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <div className="testimonial-item-name text-color-low" style={{fontSize: '20px', lineHeight: '32px'}}>
                    Our experts create innovative web applications and websites that drive unparalleled experience for your customers and business.
                  </div>
                  {/* <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a
                      href="https://bscscan.com/token/0x395678a4bab1cfe77bed9ddadca47b89a2b85dbb"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Check
                    </a>
                  </span> */}
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/custom_software.svg").default}
                    alt="Features split 03"
                    className={'testimonial-item-image'}
                  />
                  <p
                    className="text-sm mb-0"
                    style={{ color: "rgb(54, 86, 121)" , fontWeight: 700}}
                  >
                    CUSTOM SOFTWARE
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <div className="testimonial-item-name text-color-low" style={{fontSize: '20px', lineHeight: '32px'}}>
                    We build custom software that sparks innovation and streamlines your business with cutting-edge tech to deliver secure solutions.
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <Image
                    src={require("./../../assets/images/twitter-icon.png")}
                    alt="Features split 03"
                    width={100}
                    height={100}
                  />
                  <p className="text-sm mb-0">Over 1,000 Twitter followers</p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Twitter
                  </span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a
                      href="https://twitter.com/libitcat"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join Now
                    </a>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
