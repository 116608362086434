import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ProgressBar from "@ramonak/react-progress-bar";
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}


const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [percent, setPercent] = useState(0);
  const [percent2, setPercent2] = useState(0);
  const [percent3, setPercent3] = useState(0);
  const [percent4, setPercent4] = useState(0);
  const [percent5, setPercent5] = useState(0);
  const [percent6, setPercent6] = useState(0);
  const [percent7, setPercent7] = useState(0);
  const [percent8, setPercent8] = useState(0);
 
  useEffect(() => {
    // Update the document title using the browser API
    setTimeout(() => {
      setPercent(36)
      setPercent2(42)
      setPercent3(35)
      setPercent4(40)
      setPercent5(100)
      setPercent6(25)
      setPercent7(100)
      setPercent8(100)
    }, 1000);
  });

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'RUNNING DETAILS',
    paragraph: ''
  };

  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div id="RUNNINGDETAILS" className="container about-sm">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                BSC Validator Spotlight
                  </div>
                <h3 className="mt-0 mb-12">
                CONDITIONS
                  </h3>

                  <p className="m-0">
                    OPEN SOURCE CODE
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent7}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>
                
                  <p className="m-0">
                    MANY INFO VALIDATION
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent8}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>
                  
                  
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Validator-BscScan.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Gaming + NFT + Farming
                  </div>
                <h3 className="mt-0 mb-12">
                Progress
                  </h3>

                  <p className="m-0">
                    NTF farming
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent5}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>

                  <p className="m-0">
                    Lucky wheel
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent6}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>
                  <br/>
                  <Button tag="a" color="primary" wideMobile  href='/nft-intro'
                  >
                  NTF FARMs INTRODUCTION
                    </Button>
               
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/ntf.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                CoinMarketCap Listing
                  </div>
                <h3 className="mt-0 mb-12">
                CONDITIONS
                  </h3>
                <p className="m-0">
                2500 Members
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>

                  <p className="m-0">
                    Pool Liquidity
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent2}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>

              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/coinmarketcap.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                CoinGecko Listing
                  </div>
                <h3 className="mt-0 mb-12">
                CONDITIONS
                  </h3>

                  <p className="m-0">
                    Listed CoinMarketCap
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent3}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>
                
                  <p className="m-0">
                    Pool Liquidity
                  </p>
                  <div>
                      <ProgressBar 
                      completed={percent4}
                      bgColor="#1b9a30"
                      labelColor="#fffa0c"
                      transitionTimingFunction="linear"
                      />
                  </div>
                  
                  
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Coingecko.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            



            

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;