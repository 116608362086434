import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="hero--block1-div1">
              <Image
                className="image-50-no-margin"
                src={require("./../../assets/images/tanksolutions_logo.svg").default}
                alt="Hero"
                width={100}
                height={100}
              />
              <h1 className="hero--block1-div1-h1" style={{fontSize: 60}}>
                Experience excellence with us.
              </h1>
              <h4 className="hero--block1-div1-h1">Your partner in business</h4>
              <Button
                className={"hero--block1-div1-button"}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/#contact";
                }}
              >
                Contact us
              </Button>
            </div>
            {/* <Image
                className="has-shadow"
                src={require('./../../assets/images/libit-cat-icon.png')}
                alt="Hero"
                width={100}
                height={100} /> */}
            <div className="container-xs container-xs-none" style={{marginTop: 136}}>
              <Image
                className="image-full"
                src={
                  require("./../../assets/images/block1-image.png")
                }
                alt="Hero"
                width={100}
                height={100}
              />
              {/* <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                The Black Hole owned over 60% of supply at launch. Burn total 5%
                for every transaction. No wallet holds more than 1% of token
                supply
              </p> */}
              {/* <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://t.me/Libitcatchannel"
                    target="_blank"
                  >
                    Announcement
                  </Button>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://exchange.pancakeswap.finance/#/swap?&outputCurrency=0x395678a4bab1cfe77bed9ddadca47b89a2b85dbb"
                    target="_blank"
                  >
                    Buy Now
                  </Button>

                  <Button tag="a" color="primary" wideMobile href="/nft-intro">
                    NTF FARMs INTRODUCTION
                  </Button>
                </ButtonGroup> */}
            </div>
          </div>
        </div>
        <div
          className="hero-figure reveal-from-bottom illustration-element-01"
          data-reveal-value="20px"
          data-reveal-delay="800"
        >
          {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
          {/* <Image
                className="has-shadow"
                src={require('./../../assets/images/event.jpg')}
                alt="Hero"
                width={300}
                height={300} /> */}
          {/* </a> */}
          {/* <iframe title="YouTube video player" class="youtube-player" type="text/html" width="560" height="345" src="http://www.youtube.com/embed/8v_4O44sfjM?autoplay=1" frameborder="0" allowFullScreen></iframe> */}
        </div>
        <Modal
          id="video-modal"
          show={videoModalActive}
          handleClose={closeModal}
          video="https://player.vimeo.com/video/174002812"
          videoTag="iframe"
        />
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
