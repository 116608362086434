import React from 'react';
// import sections
import ROADMAPDETAIL from '../components/sections/roadmapDetailsPage';
import ROADMAPDETAILRUNING from '../components/sections/roadmapDetailRuning';

const roadmapDetail = () => {

  return (
    <>
      <ROADMAPDETAILRUNING />
      <ROADMAPDETAIL />

    </>
  );
}

export default roadmapDetail;