import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ProgressBar from "@ramonak/react-progress-bar";
import Button from '../elements/Button';
import IntroductionNFT from '../../assets/images/introduction_farm.mp4'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}


const NFTIntroComponent = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [percent7, setPercent7] = useState(0);
  const [percent8, setPercent8] = useState(0);
 
  useEffect(() => {
    // Update the document title using the browser API
    setTimeout(() => {
      setPercent7(100)
      setPercent8(95)
    }, 1000);
  });

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'LITC FARMING',
    paragraph: 'LITC finds LITM'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div id="NFTIntroComponent" className="container about-sm">
        <div className={innerClasses} >
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                FARM
                  </div>
                <h3 className="mt-0 mb-12">
                INTRODUCTION
                  </h3>

                  <p className="m-0">
                  Another GAME-themed DeFi protocol? You know it. LIBITCAT is a Binance Smart Chain-based DEX launched by anonymous devs with a penchant for CAT and MOUSE.
                  </p> <br/>
                  <p className="m-0">
                  It looks a bit like Ethereum’s SushiSwap (community governance, the ability to farm liquidity provider tokens), but also incorporates many other features that let you earn rewards.                  </p>
                 <br/>
                
                  <p className="m-0">
                    HOLDERS LITC with large amount can be stacking for recieve LITM!
                  </p>
                  <br/>
                
                  <p className="m-0">
                    LITM will be small supply with 21000 tokens total.
                  </p>
                  <div>
                  <Button tag="a" color="primary" wideMobile  href="https://t.me/libitcatfarmbot"
                  target='_blank'>
                  Join now
                    </Button>
                     {/* <a target="_blank"><h1 className="btn-shine2" >Join now</h1></a> */}
                  </div>
                  
                  
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/cat-mouse-farms.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                HOW TO START STACKING
                  </div>
                <h3 className="mt-0 mb-12">
                INTRODUCTION
                  </h3>

                  <p className="m-0">
                    A little bit easy for stacking with safety!
                  </p>
                  <br/>
                  <p className="m-0">
                    Un-staking anytime you want!
                  </p>
                  <br/>
                  <p className="m-0">
                    10% LITM of total supply will release per month!
                  </p>
                  <br/>
                  <p className="m-0">
                    All LITM will be distribution to person who farm LITC.
                  </p>
                  <br/>
                  <p className="m-0">
                    LITM no presale, no sale C2C, only farm get LITM. Who have LITM can trade after we list LITM at 31/07/2021.
                  </p>
                  <br/>
                  <p className="m-0">
                    LITM is limited with 21000 tokens supply total.
                  </p>
                 
                  <div>
                  <Button tag="a" color="primary" wideMobile  href="https://t.me/libitcatfarmbot"
                  target='_blank'>
                  Join now
                    </Button>
                     {/* <a target="_blank"><h1 className="btn-shine2" >Join now</h1></a> */}
                  </div>
                  
                  
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
               
            <div >
            <video src={IntroductionNFT} style={{borderRadius: "10px"}} width={1280} height={654} controls="controls" autoplay="true" />
              
            </div>
              </div>
            </div>

            

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                LITM INTRODUCTION
                  </div>
                <h3 className="mt-0 mb-12">
                  What you can do with LITM?
                  </h3>

                  <p className="m-0">
                    You can trade LITM on exchange after we list!
                  </p>
                  <br/>
                  <p className="m-0">
                    You can save LITM like you assets!
                  </p>
                  <br/>
                  <p className="m-0">
                    LITM will be bought clothes and game card in our service!
                  </p>
                  <br/>
                  <p className="m-0">
                    LITM can swap any other tokens you want with auto liquidity!
                  </p>
                  <br/>
                  <p className="m-0">
                    LITM is a global token for everyone want it!
                  </p>
                 
                  <div>
                  <Button tag="a" color="primary" wideMobile  href="https://t.me/libitcatfarmbot"
                  target='_blank'>
                  Join now
                    </Button>
                     {/* <a target="_blank"><h1 className="btn-shine2" >Join now</h1></a> */}
                  </div>
                  
                  
              </div>

              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/BINANCE-ECOSYSTEM.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

NFTIntroComponent.propTypes = propTypes;
NFTIntroComponent.defaultProps = defaultProps;

export default NFTIntroComponent;