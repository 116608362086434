import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import ROADMAP from '../components/sections/ROADMAP';
import ALGORITHM from '../components/sections/ALGORITHM';
import ABOUT from '../components/sections/ABOUT';
import Holder from '../components/sections/holder';
import Cta from '../components/sections/Cta';
import Slogan from '../components/sections/slogan';
import ContactUs from '../components/sections/contact-us';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      {/* <Cta split /> */}
      <Slogan split />
      <Holder topDivider />
      <ABOUT invertMobile topDivider imageFill className="illustration-section-02" />
      <ContactUs invertMobile topDivider imageFill className="illustration-section-03" />
      {/* <ALGORITHM /> */}
      {/* <ROADMAP /> */}
    </>
  );
}

export default Home;